import { ref } from '@vue/composition-api'
import store from '@/store'

export const useFiltersSortingAndPagination = (data) => {
  if(!data){
    data = {};
  }

  const filterOptions = {
  
  };

  const filters = ref({
    q: data.q || '',
    destinationOrganization: data.destinationOrganization || null,
    categories: data.categories? data.categories.split(","): [],
    labels: data.labels? data.labels.split(","): [],
    page: data.page? parseInt(data.page): 1,
    limit: data.limit ? parseInt(data.limit) : 10,
 
  })



  return {
    // Filter
    filters,
    filterOptions,
    // Sort

  }
}

export const useUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalTemplates = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalTemplates,
  }
}

export const useRemoteData = () => {
  const templates = ref([])
  const destinationOrganizations = ref([])
  const fetchTemplates = (...args) => store.dispatch('app-template/fetchTemplates', ...args)
  const assignTemplates = (...args) => store.dispatch('app-template/assignTemplates', ...args)
  const deleteTemplates = (...args) => store.dispatch('app-template/deleteTemplates', ...args)

  return {
    templates,
    fetchTemplates,
    assignTemplates,
    deleteTemplates,
    destinationOrganizations
  }
}
