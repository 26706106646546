<template>
  <div style="height: inherit">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalTemplates }} results found
              </div>
            </div>
            <div class="view-options d-flex">
              <b-button
                style="margin-right: 10px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="newTemplate"
                >New template</b-button
              >
              <b-button
                style="margin-right: 10px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="showAssignModal(curretTemplates)"
                >Assign</b-button
              >

              <b-button
                style="margin-right: 10px"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="deleteSelectedTemplates(curretTemplates)"
                >Delete</b-button
              >


            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search Product"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="template in curretTemplates"
        :key="template.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <b-link
            :to="{
              name: 'template-to-edit',
              params: { templateId: template.id },
              query: {organization: filters.destinationOrganization, organizationName: destinationOrganizationsById[filters.destinationOrganization].name}
            }"
          >
            <b-img
              :alt="`${template.name}`"
              fluid
              class="card-img-top template-image"
              :src="
                template.imageBase64 ||
                'https://images.unsplash.com/photo-1497005367839-6e852de72767?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80'
              "
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <h6 class="item-name">
            <b-link
              class="text-body"
              :to="{
                name: 'template-to-edit',
                params: { templateId: template.id },
                query: {organization: filters.destinationOrganization, organizationName: destinationOrganizationsById[filters.destinationOrganization].name}
              }"
            >
              {{ template.name }}
            </b-link>
            <b-card-text class="item-company">
              By
              <b-link class="ml-25">
                {{ template.organization }}
              </b-link>
            </b-card-text>
          </h6>
          <b-card-text class="item-description">
            {{ template.documentation.description }}
            
          </b-card-text>
          <span>{{ template.updatedAt | moment }}</span>
          <!-- <b-card-text class="item-description">
             {{ moment().format('LLLL') }}
          </b-card-text> -->
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-button
            variant="light"
            tag="a"
            class="btn-wishlist"
            @click="showCloneModal(template)"
          >
            <span>Clone</span>
          </b-button>
          <b-button
            variant="primary"
            class="btn-wishlist"
            @click="showAssignModal([template])"
          >
            <span>Assign</span>
          </b-button>
          <b-button
            variant="danger"
            tag="a"
            class="btn-cart"
            @click="deleteSelectedTemplates([template])"
          >
            <span>Delete</span>
          </b-button>
        </div>
      </b-card>
    </section>

    <!-- Pagination -->
    <section>
      <b-row v-if="totalTemplates>0">
        <b-col cols="12">
          <b-pagination
             v-model="filters.page"
            :total-rows="totalTemplates"
            :per-page="filters.limit"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>

    <!-- Sidebar -->

    <!-- Sidebar -->
    <portal
      v-if="filterOptions.categories"
      to="content-renderer-sidebar-detached-left"
    >
      <div>
        <destination-sidebar
          :filters="filters"
          :organizations="destinationOrganizations"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        />
        <templates-left-filter-sidebar
          :filters="filters"
          :filter-options="filterOptions"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        />
      </div>
    </portal>
    <b-modal
      id="modal-prevent-closing"
      ref="clone-modal"
      title="Enter Template Name"
      ok-title="Clone"
      centered
      cancel-variant="outline-secondary"
      @hidden="cloneResetModal"
      @ok="cloneHandleOk"
    >
      <form ref="cloneform" @submit.stop.prevent="cloneHandleSubmit">
        <b-form-group
          :state="cloneNameState"
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="cloneName"
            :state="cloneNameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      id="modal-prevent-closing"
      ref="assign-modal"
      title="Assign templates"
      ok-title="Assign"
      centered
      cancel-variant="outline-secondary"
      @hidden="assignResetModal"
      @ok="assignHandleOk"
    >
      <form ref="assignform" @submit.stop.prevent="assignHandleSubmit">
        <b-form-group
          :state="assignOrganizationsState"
          label="Target organization"
          label-for="name-input"
          invalid-feedback="Organization is required"
        >
          <v-select v-model="assignOrganizations" 
              label="value" :reduce="option => option.id" :options="filterOptions.organizations" />
        
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import TemplatesLeftFilterSidebar from "./TemplatesLeftFilterSidebar.vue";
import DestinationSidebar from "./DestinationSidebar.vue";
import {
  useFiltersSortingAndPagination,
  useUi,
  useRemoteData,
} from "./useTemplate";
import organizationStoreModule from "../../organization/organizationStoreModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import axios from "@axios";
import router from '@/router';
import vSelect from 'vue-select';
import moment from "moment"
export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BFormGroup,
    // SFC
    TemplatesLeftFilterSidebar,
    DestinationSidebar,
    vSelect
  },
  computed: {
    curretTemplates() {
      return this.templates;
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('LLLL');
    }
  },
  methods: {
    newTemplate() {
      this.$router.push({
        name: 'apps-template-editor',
        query: { organization: this.filters.destinationOrganization, organizationName: this.destinationOrganizationsById[this.filters.destinationOrganization].name },
      });
    },
    showCloneModal(templateToClone) {
      this.templateToClone = templateToClone.id
    
      this.$refs["clone-modal"].show();
    },
    cloneCheckFormValidity() {
      const valid = this.$refs.cloneform.checkValidity();
      this.cloneNameState = valid;
      return valid;
    },
    cloneResetModal() {
      this.cloneName = "";
      this.cloneNameState = null;
      this.templateToClone = null;
    },
    cloneHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.cloneHandleSubmit();
      this.templateToClone = null;
    },
    cloneHandleSubmit() {
      // Exit when the form isn't valid
      if (!this.cloneCheckFormValidity()) {
        return;
      }

      axios
        .post(`/templates/clone/${this.templateToClone}`, {name: this.cloneName})
        .then((response) => this.fetchTheTemplates())
        .catch((error) => console.log(error));

      this.$nextTick(() => {
        this.$refs["clone-modal"].toggle("#toggle-btn");
      });
    },


    showAssignModal(templatesToAssign) {
      this.templatesToAssign = templatesToAssign.map(x=>x.id);
    
      this.$refs["assign-modal"].show();
    },
    assignCheckFormValidity() {
      const valid = this.$refs.assignform.checkValidity();
      this.assignOrganizationsState = valid;
      return valid;
    },
    assignResetModal() {
      this.assignOrganizations = null;
      this.assignOrganizationsState = null;
      this.templatesToAssign = null;
    },
    assignHandleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.assignHandleSubmit();
      this.assignResetModal();
    },
    assignHandleSubmit() {
      // Exit when the form isn't valid
      if (!this.assignCheckFormValidity()) {
        return;
      }
      this.assignTemplates({
        templates: this.templatesToAssign,
        destinationOrganization: this.assignOrganizations,
      }).then((res) => {
        this.fetchTheTemplates();
      });
     

      this.$nextTick(() => {
        this.$refs["assign-modal"].toggle("#toggle-btn");
      });
    },

   
    deleteSelectedTemplates(templatesToDelete) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure that you want to delete the selected temlates?",
          {
            title: "Delete Templates",
            size: "sm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.deleteTemplates({
              templates: templatesToDelete.map((x) => x.id),
            }).then((res) => {
              this.fetchTheTemplates();
              console.log(res);
            });
          }
        });
    },
  },
  data() {
    return {
      cloneName: "",
      cloneNameState: null,
      templateToClone: null,
      assignOrganizations: null,
      assignOrganizationsState: null,
      templatesToAssign: null
    };
  },
  destroyed() {
    this.$store.commit(
      "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
      this.isVerticalMenuCollapsed
    );
  },
  setup() {
    store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
    function startLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "contents";
    }
    function stopLoading() {
      const appLoading = document.getElementById("loading-bg");
      appLoading.style.display = "none";
    }

    onUnmounted(() => {
      if (store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
        store.unregisterModule(ORGANIZATION_APP_STORE_MODULE_NAME);
    });

    const ORGANIZATION_APP_STORE_MODULE_NAME = "app-organization";
    if (!store.hasModule(ORGANIZATION_APP_STORE_MODULE_NAME))
      store.registerModule(
        ORGANIZATION_APP_STORE_MODULE_NAME,
        organizationStoreModule
      );

    const { filters, filterOptions } =
      useFiltersSortingAndPagination(router.currentRoute.query);

    const { itemView, itemViewOptions, totalTemplates } = useUi();

    const {
      templates,
      fetchTemplates,
      assignTemplates,
      deleteTemplates,
      destinationOrganizations,
    } = useRemoteData();
    const destinationOrganizationsById = {};
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    const fetchTheTemplates = () => {
      startLoading();
      const orgs = [];
      if (filters.value.organization) {
        orgs.push(filters.value.organization);
      }
      if (filters.value.destinationOrganization) {
        orgs.push(filters.value.destinationOrganization);
      }
      const query = {
        q: filters.value.q,
        categories: filters.value.categories.map((x) => x.id).join(","),
        organizations: orgs.join(","),
        labels: filters.value.labels.map((x) => x.id).join(","),
        page: filters.value.page,
        limit: filters.value.limit,
        selectedFields: "id imageBase64 name organization documentation updatedAt"
      }
      if(router.currentRoute.query.namespace){
        query.namespace = router.currentRoute.query.namespace;
      }
  
      if(router.currentRoute.query.templateTypes){
        query.templateTypes = router.currentRoute.query.templateTypes;
      }
      router.replace({
          params: router.currentRoute.params,
          query: {...router.currentRoute.query,...filters.value, ...query}
      });
      fetchTemplates(query).then((response) => {
        stopLoading();
        templates.value = response.data.templates.results;
        totalTemplates.value = response.data.templates.totalResults;
        filterOptions.categories = response.data.categories;
        filterOptions.labels = response.data.labels;
      });
    };
    store.dispatch("app-organization/fetchOrganizations", {selectedFields: "id name", limit: 1000}).then((response) => {
      const organizations = response.data.results;
      destinationOrganizations.value = organizations.map((x) => {
        destinationOrganizationsById[x.id]= x;
        return {
          id: x.id,
          value: x.name,
        };
      });
      filterOptions.organizations = destinationOrganizations.value;
      if(!filters.value.destinationOrganization){
        filters.value.destinationOrganization = destinationOrganizations.value[0].id;
      }else {
        fetchTheTemplates();
      }
      
    });

    watch(
      [filters],
      () => {
        fetchTheTemplates();
      },
      {
        deep: true,
      }
    );

    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      destinationOrganizations,
      destinationOrganizationsById,
      // useShopUi
      itemView,
      itemViewOptions,
      totalTemplates,
      assignTemplates,
      deleteTemplates,
      // useShopRemoteData
      templates,
      fetchTheTemplates,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.template-image {
  height: 160px;
  width: 100%;
  object-fit: cover !important;
}
.item-img {
  min-height: 160px !important;
}
.item-img a {
  width: 100%;
}
</style>
